import "./App.css";
import "antd/dist/reset.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Loading from "./components/atomics/Loading/Loading";
import Index from "./pages/Index";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import TermsofUse from "./pages/privacy/TermsofUse";
import PrivacyPolicy from "./pages/privacy/PrivacyPolicy";
import PrivacyCollect from "./pages/privacy/PrivacyCollect";
import PrivacyWitak from "./pages/privacy/PrivacyWiTak";
import Information from "./pages/privacy/Information";
import AdressAdmit from "./pages/privacy/AddressAdmit";

function App() {
  return (
    <>
      <Loading />
      <Routes>
        <Route path="/*" element={<Index />} />

        {/* ------------- 약관동의 ------------*/}
        {/* 회원가입 약관 */}
        {/* 이용약관 */}
        <Route path="/termsofuse" element={<TermsofUse />} />
        {/* Todo 개인정보 처리 방침 */}
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        {/* 개인 정보 수집 및 이용 */}
        <Route path="/privacycollect" element={<PrivacyCollect />} />
        {/* 개인정보처리 위탁동의 */}
        <Route path="/privacywitak" element={<PrivacyWitak />} />
        {/* 개인민감정보처리방침 */}
        <Route path="/information" element={<Information />} />
        {/* 위치정보 이용약관 */}
        <Route path="/adressadmit" element={<AdressAdmit />} />
      </Routes>
    </>
  );
}

export default App;
