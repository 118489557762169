import React, {useRef, useState} from 'react'

const ModalContext = React.createContext<any>(null)

const useModal = () => {
  const context = React.useContext(ModalContext)
  if (context === undefined) {
    throw new Error('useModal must be used within a ModalProvider')
  }
  return context
}

const ModalProvider = (props: any) => {
  const [modal, setModal] = useState<any>(null)
  const bodyRef = useRef<HTMLBodyElement>(document.querySelector('body'))

  const removeAttribute = () => {
    bodyRef.current?.removeAttribute('style')
    return false
  }
  const handleClick = (modal: React.ReactNode) => {
    if (modal) {
      setModal(modal)
      bodyRef.current?.setAttribute(
        'style',
        'overflow: hidden; touch-action: none;',
      )
    } else {
      setModal(null)
      removeAttribute()
    }
  }

  const closeModal = () => {
    removeAttribute()
    setModal(null)
  }

  return (
    <ModalContext.Provider value={{modal, setModal: handleClick, closeModal}}>
      {props.children}
      {modal}
    </ModalContext.Provider>
  )
}
export {ModalProvider, useModal}
