import React from "react";
import styled from "styled-components";
import Slide from "../../components/third/Slide";
import Slide2 from "../../components/third/Slide2";
import BasicText from "../../components/atomics/Text/BasicText";
import logo from "../../assets/img/f5_logo2.png";
import logo2 from "../../assets/img/f5_logo_text_flex.png";
import f5web from "../../assets/img/f5web.png";

const SecondPage = () => {
  return (
    <>
      <StyledMain>
        <div className="header">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "0.5em",
            }}
          >
            <div className="logo">
              <img src={f5web} className="logo-img" alt="logo" />
            </div>
            <div>
              <BasicText
                bold={500}
                size={40}
                lineHeight={1.2}
                className="text__header"
              >
                만의 새로운 컨텐츠
              </BasicText>
            </div>
          </div>
          <div>
            <BasicText
              bold={700}
              size={60}
              lineHeight={1.2}
              className="text__contents"
            >
              모두가 궁금한 컨텐츠
              <br /> 새로고침과 함께 즐겨보세요.
            </BasicText>
          </div>
        </div>
      </StyledMain>
      {/* <Slide /> */}
      <Slide2 />
    </>
  );
};

export default SecondPage;

const StyledMain = styled.div`
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5em 0 5em 0;
  gap: 1em;

  .header {
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 3em;
  }

  .logo {
    display: flex; /* flexbox를 사용하여 정렬 */
    justify-content: center; /* 수평으로 가운데 정렬 */
    align-items: center; /* 수직으로 가운데 정렬 */
    height: 80px; /* 고정된 높이를 주어 간격 맞추기 */

    border-radius: 15px;
  }

  .logo-img {
    display: block; /* 이미지가 인라인 요소로 처리되는 문제 방지 */
    max-width: 100%; /* 이미지를 컨테이너에 맞게 제한 */
    max-height: 100%; /* 높이도 제한 */
    margin: 0 auto; /* 가운데 정렬을 위한 마진 */
  }

  @media (max-width: 768px) {
    .text__contents {
      font-size: 44px;
    }
  }

  @media (max-width: 500px) {
    .text__contents {
      font-size: 30px;
    }
  }

  @media (max-width: 400px) {
    .text__header {
      font-size: 30px;
    }
  }
`;
