import React from "react";
import styled from "styled-components";
import Colors from "../../../constants/Colors";

export interface IBasicText {
  bold?: number;
  size?: number;
  lineHeight?: number | string;
  color?: string;
  children?: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
}

const BasicText: React.FC<IBasicText> = ({
  children,
  bold = 400,
  size = 14,
  lineHeight = "14.4px",
  color = Colors.black,
  className = "",
  ...restProps
}) => {
  return (
    <StyledText
      bold={bold}
      size={size}
      lineHeight={lineHeight}
      color={color}
      className={className}
      {...restProps}
    >
      {children}
    </StyledText>
  );
};

export default BasicText;

const StyledText = styled.span<IBasicText>`
  font-family: ${({ bold }) => getFontFamily(bold)};
  font-weight: ${({ bold }) => bold};
  font-size: ${({ size }) => size}px;
  line-height: ${({ lineHeight }) => lineHeight};
  color: ${({ color }) => color};
`;

const getFontFamily = (bold: number | undefined) => {
  const fontWeight = bold || 400; // bold 값이 undefined일 경우 기본값 400을 사용

  switch (fontWeight) {
    case 400:
      return "Pretendard";
    case 500:
      return "Pretendard";
    case 600:
      return "Pretendard";
    case 700:
      return "Pretendard";
    default:
      return "Pretendard";
  }
};
