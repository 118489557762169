import React, { useEffect, useState } from "react";
import bannerImg from "../../assets/img/per02.jpg";
import {
  FacebookOutlined,
  InstagramOutlined,
  TikTokOutlined,
  TwitterOutlined,
  YoutubeOutlined,
} from "@ant-design/icons";
import styled from "styled-components";
import back from "../../assets/img/back22.png";
import BasicText from "../atomics/Text/BasicText";
// import logo from "../../assets/img/f5_logo2.png";
import f5_logo_test from "../../assets/img/f5_logo_test.png";
import Colors from "../../constants/Colors";

interface IconProps {
  top: string;
  left: string;
}
const ICON_POSITIONS = [
  {
    key: "instagram",
    default: { top: "40%", left: "50%" },
    small: { top: "55%", left: "10%" },
    verySmall: { top: "35%", left: "5%" },
  },
  {
    key: "youtube",
    default: { top: "60%", left: "35%" },
    small: { top: "75%", left: "25%" },
    verySmall: { top: "80%", left: "20%" },
  },
  {
    key: "twitter",
    default: { top: "20%", left: "40%" },
    small: { top: "35%", left: "65%" },
    verySmall: { top: "50%", left: "24%" },
  },
  {
    key: "tiktok",
    default: { top: "70%", left: "55%" },
    small: { top: "70%", left: "50%" },
    verySmall: { top: "65%", left: "40%" },
  },
];

const BannerImg = () => {
  const width = window.innerWidth;

  const isVerySmallScreen = width <= 480;
  const isSmallScreen = width > 480 && width <= 768;

  return (
    <StyledMain>
      <img
        src={bannerImg}
        width="100%"
        height={500}
        className="banner__img"
        alt="banner"
      />

      <StyledOverlay>
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <img src={f5_logo_test} height={60} alt="logo" className="logo" />

          <BasicText
            bold={700}
            size={35}
            color={Colors.primary}
            className="title"
          >
            새로고침
          </BasicText>
        </div>

        <div
          style={{
            display: "flex",
          }}
        >
          <BasicText bold={600} size={20} lineHeight={1.3} className="content">
            다양한 뷰티 컨텐츠 영상 플랫폼
            <br /> 지금 바로 만나보세요 !
          </BasicText>
        </div>
      </StyledOverlay>

      {ICON_POSITIONS.map(({ key, default: defaultPos, small, verySmall }) => (
        <StyledIcon
          key={key}
          top={
            isVerySmallScreen
              ? verySmall.top
              : isSmallScreen
              ? small.top
              : defaultPos.top
          }
          left={
            isVerySmallScreen
              ? verySmall.left
              : isSmallScreen
              ? small.left
              : defaultPos.left
          }
        >
          {key === "instagram" && <InstagramOutlined />}
          {key === "youtube" && <YoutubeOutlined />}
          {key === "twitter" && <TwitterOutlined />}
          {key === "tiktok" && <TikTokOutlined />}
        </StyledIcon>
      ))}
    </StyledMain>
  );
};

export default BannerImg;

const StyledMain = styled.div`
  position: relative; // Icon의 기준점이 되는 컨테이너
  background: linear-gradient(
    to bottom,
    #ebebeb,
    #ebebeb,
    #e4e4e4
  ); // 위에서 아래로 그라데이션

  background-image: ${back};

  .banner__img {
    object-fit: cover; // 이미지를 컨테이너에 맞추면서 비율 유지
    object-position: 30vh center; // 이미지의 중앙을 표시
  }

  @media (max-width: 1100px) {
    .banner__img {
      object-fit: cover; // 이미지를 컨테이너에 맞추면서 비율 유지
      object-position: 10em center; // 이미지의 중앙을 표시
    }
  }

  @media (max-width: 768px) {
    .banner__img {
      object-fit: cover; // 이미지를 컨테이너에 맞추면서 비율 유지
      object-position: center; // 이미지의 중앙을 표시
      // margin-top: 200px;
    }
  }

  @media (max-width: 480px) {
    .banner__img {
      // object-fit: contain;
      // object-fit: none;
      // object-position: center; // 이미지의 중앙을 표시
    }
  }
`;

// 아이콘 스타일 (위치 동적 설정)
const StyledIcon = styled.div.attrs<IconProps>((props) => ({
  style: {
    top: props.top,
    left: props.left,
  },
}))`
  background-color: #21b9d6;
  font-size: 40px;
  color: white;
  padding: 10px;
  border-radius: 50%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    top: calc(${(props) => props.top} - 120%); // 작은 화면에서 위치 이동
    left: calc(${(props) => props.left} - 35%); // 작은 화면에서 위치 이동
  }

  @media (max-width: 480px) {
    top: calc(${(props) => props.top} - 20%);
    left: calc(${(props) => props.left} - 10%);
    font-size: 20px;
    padding: 5px;
  }
`;

const StyledOverlay = styled.div`
  position: absolute;
  top: 40%; // 텍스트를 가운데 배치
  left: 10%;
  display: flex;
  flex-direction: column;
  gap: 1em;

  h1 {
    font-size: 36px; // 큰 제목
    margin: 0;
  }

  p {
    margin-top: 10px;
  }

  @media (max-width: 1200px) {
    top: 25%;
    left: 5%;
  }

  @media (max-width: 768px) {
    top: 10%;
    left: 5%;
  }

  @media (max-width: 480px) {
    top: 5%;
    left: 5%;

    .title {
      font-size: 20px;
    }

    .logo {
      height: 40px;
    }
    .content {
      font-size: 15px;
      display: none;
    }
  }
`;
