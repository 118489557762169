import { Button } from "antd";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import BasicText from "../../components/atomics/Text/BasicText";
import Colors from "../../constants/Colors";
import PrivacyHeader from "./PrivacyHeader";
import Link from "antd/es/typography/Link";

// 개인정보처리 위탁 동의

const PrivacyWitak = () => {
  const nvaigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <PrivacyHeader homeIcon />

      <StyledMainContainer>
        <div className="text-area">
          <h1>
            <BasicText bold={700} size={23} color={Colors.primary}>
              (주)엠에이디씨 개인정보처리 위탁 동의
            </BasicText>
          </h1>
          <br />

          <div>
            <BasicText bold={400} color={Colors.gray4}>
              개인정보 처리 위탁
              <br />- (주)엠에이디씨는 서비스 제공을 위해 필요한 경우 개인정보
              처리 업무 중 일부를 외부에 위탁 할 수 있습니다.
              <br />
              개인정보 안전성 확보조치 및 재위탁 제한 등 위탁받은 업체의
              개인정보 보호 관련 법령 준수 여부를 관리·감독하고 있습니다.
              <br /> [개인정보 처리 위탁 현황_국내]
              {/* Todo 위탁 테이블 */}
              <br /> 수탁업체 위탁업무 내용 SKB, 가비아 SMS 발송 서비스
              애드저스트 코리아 제휴사 맞춤 광고 서비스 개발 및 제공
              드림시큐리티 본인인증(PASS) 서비스 Amazon Web Service 시스템 제공
              및 관리 카카오 SNS 가입 여부 및 계정 정보 확인, 맞춤 광고 서비스
              네이버 SNS 가입 여부 및 계정 정보 확인 페이스북 SNS 가입 여부 및
              계정 정보 확인 애플 SNS 가입 여부 및 계정 정보 확인 구글(구글애즈)
              고객 맞춤형 광고 제공 CRITEO 고객 맞춤형 광고 제공 아임포트 결제
              API 서비스 제공 이니시스 PG 결제서비스 제공 현대해상 부작용
              안전보장 서비스 제공 핵클 서비스 이용 통계 및 분석 ㈜써머스플랫폼
              카카오 비즈 메시지 서비스 제공 헥토파이낸셜 가상계좌 발급 서비스
              제공 바이브알씨 (주)엠에이디씨 영업 업무 위탁 애드플라이어
              (주)엠에이디씨 영업 업무 위탁 MPR (주)엠에이디씨 영업 업무 위탁
              위카 (주)엠에이디씨 영업 업무 위탁 한강 (주)엠에이디씨 영업 업무
              위탁 트리니티랩스 (주)엠에이디씨 영업 업무 위탁 와커스
              (주)엠에이디씨 영업 업무 위탁 파이메이커스 (주)엠에이디씨 영업
              업무 위탁
              <br />
              <br />
              {/* [개인정보 처리 위탁 현황_국외]
              <br />
              <br />
              탁업체 이전 목적 위탁 개인정보 항목 위탁 일시 및 방법 업체위치
              (국가) 연락처 개인정보 이용기간 구글 서비스 이용 통계 및 분석
              성별, 지역, 가입일자, 앱 내 행동로그 서비스 이용 시점에 네트워크를
              통한 전송 미국 (1600 Amphitheatre Parkway Mountain View,
              California 94043 USA) google_da_kr@dagent.co.kr 본 개인정보
              처리방침에 규정된 보관기간과 일치함 센드버드 채팅서비스 이용 및
              보관 성별, 채팅메시지 서비스 이용 시점에 네트워크를 통한 전송
              미국(400 1st Ave,San Mateo, CA 94401) Legal@senbird.com 앰플리튜드
              서비스 이용 통계 및 분석 IP, 성별, 지역, 가입일자, 앱내 행동로그
              서비스 이용 시점에 네트워크를 통한 전송 미국 (Amplitude SF, 631
              Howard St. San Francisco, CA 94105) Privacy@amplitude.com 통계
              추출 후 즉시 삭제 브레이즈 맞춤 CRM 메시지 발송(앱 푸시 등) 및
              모바일 알림 서비스 제공 자동 수집 정보, 디바이스 정보, 마케팅 수신
              동의 여부 서비스 이용 시점에 네트워크를 통한 전송 미국(General
              Counsel 330 West 34th Street, 18th Floor New York, NY 10001)
              Privacy@braze.com AWS 서비스 이용 통계 및 분석 앱 이용 정보 통계
              추출 시점에 네트워크를 통한 전송 일본(도쿄리전)
              <br />
              <br /> - 이용자는 회사의 개인정보보호책임자 및 담당부서를 통해
              개인정보의 국외이전을 거부할 수 있습니다.
              <br />- 이용자가 개인정보의 국외 이전을 거부하는 경우 회사는 해당
              이용자의 개인정보를 국외 이전 대상에서 제외합니다. 이 경우회사의
              서비스 중 개인정보 국외 이전이 필수적으로 수반되는 서비스의 이용이
              제한될 수 있습니다. <br />
              <br />※ 위와 같은 개인정보처리위탁 동의를 거부하실 수 있으나, 이
              경우 (주)엠에이디씨 회원가입이 제한됩니다. */}
              <br />
            </BasicText>
            <br />
            <Link href="/privacypolicy">개인정보처리방침 전문보기</Link>
          </div>
          {/* <Button
            onClick={() => nvaigate(-1)}
            style={{
              marginTop: 20,
              width: 200,
            }}
          >
            확인
          </Button> */}
        </div>
      </StyledMainContainer>
    </>
  );
};

export default PrivacyWitak;

const StyledMainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .text-area {
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }
`;
