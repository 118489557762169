import React from "react";
import { Col, Layout, Row } from "antd";
import styled from "styled-components";
import logo from "../../assets/img/icon_navi_logo.png";
import home from "../../assets/img/icon/tabs/icon_home_primary.png";
import BasicText from "../../components/atomics/Text/BasicText";
import Link from "antd/es/typography/Link";
import { title } from "process";
import Colors from "../../constants/Colors";

const { Header: AntHeader } = Layout;

const StyledHeader = styled(AntHeader)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 64px;
  padding: 0 1em;
  width: 100%;
  background-color: ${Colors.primaryLighten};
`;

const StyledRow = styled(Row)`
  width: 100%;
`;

interface IHeaderProps {
  homeIcon?: boolean;
  layout?: string;
  title?: string;
}

// privacy header
const PrivacyHeader: React.FC<IHeaderProps> = ({
  homeIcon = false,
  layout = 0,
  title,
}) => {
  return (
    <StyledRow
      justify="space-between"
      align="middle"
      style={{
        marginTop: layout,
      }}
    >
      <StyledHeader>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "0.5em",
          }}
        >
          <img src={logo} width={20} alt="logo" />
          <BasicText bold={700} size={20} color={Colors.gray4}>
            나비톡
          </BasicText>
        </div>

        {homeIcon ? (
          <BasicText bold={700} size={18} color={Colors.text}>
            {title}
          </BasicText>
        ) : (
          <div>
            <Link href="/">
              <img src={home} width={30} alt="home" />
            </Link>
          </div>
        )}
      </StyledHeader>
    </StyledRow>
  );
};

export default PrivacyHeader;
