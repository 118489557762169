import { Button } from "antd";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import BasicText from "../../components/atomics/Text/BasicText";
import Colors from "../../constants/Colors";
import { StyleTitleProps } from "./PrivacyPolicy";
import PrivacyHeader from "./PrivacyHeader";

// 개인민감정보처리방침
const Information = () => {
  const nvaigate = useNavigate();

  const StyleTitle: React.FC<StyleTitleProps> = ({ children }) => (
    <>
      <br />
      <br />
      <br />
      <BasicText bold={700} size={23} color={Colors.text}>
        {children}
      </BasicText>
    </>
  );

  const StyleSubTitle: React.FC<StyleTitleProps> = ({ children }) => (
    <>
      <br />
      <br />
      <BasicText bold={700} size={18} color={Colors.text}>
        {children}
      </BasicText>
      <br />
      <br />
    </>
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <PrivacyHeader homeIcon />

      <StyledMainContainer>
        <div className="text-area">
          <BasicText bold={700} size={23} color={Colors.primary}>
            개인정보취급방침
          </BasicText>

          <div>
            <StyleSubTitle>개인정보취급방침</StyleSubTitle>{" "}
            <BasicText bold={400} color={Colors.gray4}>
              (주)엠에이디씨(이하"을")는 “(주)엠에이디씨”에서 제공하는 온라인
              웹사이트 관련 서비스(이하" 서비스"라 함)를 이용하면서 회원이
              제공하는 개인정보가 보호받을 수 있도록 최선을 다하고 있습니다.
              <br />
              <br />
              이에 “(주)엠에이디씨”는 통신비밀보호법, 전기통신사업법, 정보통신망
              이용촉진 및 보호에 관한법률등 정보통신 서비스 제공자가
              준수하여야할 관련 법규 및 지침을 지키고 있습니다.
              <br />
              <br />
              수집하는 정보는 회원 여러분이 동의한 목적과 범위 내에서만
              이용되며, 법령에 의거하거나 여러분이 별도로 동의하지 아니하는 그
              범위를 초과하여 이용하거나 외부에 공개하지 않습니다.
              <br />
              <br /> “(주)엠에이디씨”는 개인정보처리방침을 통하여 회원 여러분이
              제공하는 개인정보가 어떠한 용도와 방식으로 이용되고 있으며,
              개인정보보호를 위해 어떠한 조치가 취해지고 있는지 알리며, 수시로
              확인할 수 있도록 조치하고 있습니다. 또한 개인정보 처리방침이
              변경되었을 때는 변경이유 및 내용에 관하여 웹사이트 공지사항 등을
              통하여 공지할 것이며 변경된 사항을 회원 여러분이 쉽게 알아볼 수
              있도록 게시하겠습니다.
              <br /> 1. 개인정보의 수집 및 이용목적 "개인정보"라 함은 생존하는
              개인에 관한 정보로서 당해 정보에 포함되어 있는 성명, 본인확인기관
              인증정보 등의 사항에 의하여 당해 개인을 식별할 수 있는 정보(당해
              정보만으로는 특정 개인을 식별할 수 없더라도 다른 정보와 용이하게
              결합하여 식별할 수 있는 것을 포함)를 말합니다. (주)엠에이디씨는
              이용자의 사전 동의 없이는 이용자의 개인 정보를 함부로 공개하지
              않으며, 수집된 정보는 아래와 같이 이용하고 있습니다. <br />
              <br />
              첫째, 이용자들이 제공한 개인정보를 바탕으로 보다 더 유용한
              서비스를 개발할 수 있습니다. (주)엠에이디씨는 신규 서비스개발이나
              컨텐츠의 확충시에 기존 이용자들이 (주)엠에이디씨에 제공한
              개인정보를 바탕으로 개발해야 할 서비스의 우선 순위를 보다 더
              효율적으로 정하고, (주)엠에이디씨는 이용자들이 필요로 할 컨텐츠를
              합리적으로 선택하여 제공할 수 있습니다.
              <br />
              <br />
              둘째, 각 수집정보별 수집목적은 다음과 같습니다.
              <br /> 1) 성명, 아이핀(I-pin) 또는 본인확인기관
              인증정보(본인명의의 휴대폰), 생년월일, 아이디, 비밀번호 :
              서비스이용에 따른 본인식별, 연령제한 서비스의 제공
              <br /> 2) 이메일주소, 전화번호 : 고지사항 전달, 본인의사확인,
              불만처리 등 원활한 의사소통 경로의 확보, 새로운 서비스나
              신상품,이벤트 정보 등 최신정보의 안내
              <br /> 3) 은행계좌정보,신용카드정보,핸드폰번호,전화번호 :
              유료정보이용에 대한 요금결재
              <br /> 4) 주소,전화번호 : 청구서, 우편물 배송, 계약변동사항 고지
              <br />
              <br />
              셋째, 개인정보 수집방법 <br />
              1) 홈페이지(회원가입 및 계약/결제시)
              <br /> 2) 상품 및 서비스 이용 및 구매시 정보입력, 이벤트등의
              프로모션 진행시
              <br /> 3) 제휴사로부터의 제공
              <br /> 2. 개인정보의 수집항목 “(주)엠에이디씨”는 회원 여러분에게
              다양한 서비스를 제공하기 위해 아래와 같은 개인정보를 수집하고
              있습니다.
              <br /> ① 필수항목: 이름, 주민번호, 휴대전화번호, 주소, 이메일,
              사업자번호(기업회원), 기업명(기업회원)
              <br /> ② 서비스 이용 또는 사업처리 과정에서 생성/수집되는 각종
              거래 및 개인성향정보: 공간 예약 및 결제 기록, OS버전, 단말기
              기기명, 고유기기 식별 값, 접속 기록, 접속IP정보, 쿠키(Cookie),
              기기고유번호 등 이외에도 고객 본인의 실명확인과 기타 서비스에
              필요한 추가적인 정보제공을 요청할 수 있으며 각종 설문조사 및
              이벤트 시 집단 통계분석, 경품발송 및 본인확인을 위해 개인정보를
              요청할 수 있습니다.
              <br />
              <br />
              이때에도 기입하신 정보는 해당서비스 제공이나 회원님께 사전에 밝힌
              목적 이외의 다른 어떠한 목적으로도 사용되지 않음을 알려 드립니다.
            </BasicText>
            <StyleSubTitle>3. 개인정보 보유방법 및 파기</StyleSubTitle>{" "}
            <BasicText bold={400} color={Colors.gray4}>
              회원으로서 “(주)엠에이디씨”가 제공하는 서비스를 받으시는 동안
              고객님의 개인정보는 “(주)엠에이디씨”에서 정확하고 안전하게 관리,
              보유되며 보다 나은 서비스를 제공하는데 쓰여 지게 됩니다. 만일
              고객님께서 가입해지를 요청하시거나 고객님께 사전에 알려드린
              개인정보 수집 목적을 달성한 경우 수집된 모든 개인정보를 다음
              각호의 방법에 의해 처리됩니다.
              <br />① 종이에 인쇄된 고객개인정보 : 분쇄기를 통하여 분쇄하거나
              소각
              <br />② 전자적 파일의 형태로 저장된 고객개인정보 : 정보를 재생할
              수 없는 기술적 방법을 사용하여 삭제 단, 고객님의 동의나 관계법령에
              특별한 규정이 있는 경우에 한해 예외로 합니다.
              <br />
            </BasicText>
            <StyleSubTitle>4. 개인정보처리의 위탁</StyleSubTitle>
            <BasicText bold={400} color={Colors.gray4}>
              ① ‘(주)엠에이디씨’는 원활한 개인정보 업무처리 및 서비스 향상을
              위하여 관계법령에 따라 회원의 동의를 얻거나 관련 사항을 공개 또는
              고지 후 회원의 개인정보를 외부에 위탁하여 처리하고 있습니다.
              (주)엠에이디씨의 개인정보처리 수탁자와 그 업무 내용은 다음과
              같습니다.
              <br /> A. 서비스 예약 및 신청 정보 제공 - 수탁자 : 페이레터 - 위탁
              업무 내용 : 신용카드 결제정보 전송 - 위탁 기간 : 위탁 계약 종료
              시까지
              <br /> B. 카카오톡 알림톡 발송 - 수탁자 : (주)루나소프트 -
              개인정보제공처 : 주식(주)엠에이디씨 카카오 - 위탁 업무 내용 :
              카카오톡 알림톡(정보성 메시지) 발송 업무 - 위탁 기간 : 위탁 계약
              종료 시 까지
              <br />② (주)엠에이디씨는 위탁계약 체결 시 개인정보보호법 제25조에
              따라 위탁업무 수행목적 외 개인정보 처리금지, 기술적.관리적
              보호조치, 재위탁 제한, 수탁자에 대한 관리.감독, 손해배상 등 책임에
              관한 사항을 계약서 등 문서에 명시하고, 수탁자가 개인정보를
              안전하게 처리하는지를 감독하고 있습니다.
              <br /> ③ 위탁 업무의 내용이나 수탁자가 변경될 경우에는 지체없이 본
              개인정보처리방침을 통하여 공개하도록 하겠습니다.
            </BasicText>
            <StyleSubTitle>5. 개인정보의 제3자</StyleSubTitle>
            <BasicText bold={400} color={Colors.gray4}>
              제공, 정보/광고 수신 동의
              <br />① “(주)엠에이디씨”는 회원 여러분의 개인정보를"
              <br />
              1. 개인정보의 수집목적"에서 고지한 범위를 넘어서 이용하거나
              원칙적으로 여러분의 개인정보를 외부에 공개하지 않습니다. 단,
              아래의 경우에는 예외로 합니다. <br />
              A.회원이 사전에 동의한 경우
              <br /> B.서비스의 제공에 관한 계약을 이행하는 데 필요한
              개인정보로서 경제적, 기술적인 사유로 회원으로부터 일반적인 동의를
              얻는 것이 매우 어려운 경우
              <br /> C.법령의 규정에 근거하거나 수사 목적으로 법령에 정해진
              절차와 방법에 따라 수사기관이 개인정보의 제공을 요청하는 경우
              <br /> ② “(주)엠에이디씨”가 영업의 전부 또는 일부를 양도하거나,
              합병, 상속 등으로 서비스제공자의 권리, 의무를 이전 승계할 때에는
              권리를 보장하기 위하여 반드시 그 사실을 고객님께 통지합니다.
            </BasicText>
            <StyleSubTitle> 6. 개인정보의 보유 및 이용기간</StyleSubTitle>{" "}
            <BasicText bold={400} color={Colors.gray4}>
              ① “(주)엠에이디씨”는 회원가입일로부터 서비스를 제공하는 기간에
              한하여 회원 여러분의 개인정보를 보유하고 이용하게 됩니다.
              <br />② “(주)엠에이디씨”는 회원 여러분이 서비스 탈퇴를 요청하거나
              개인정보의 수집 및 이용에 대한 동의를 철회할 때, 수집 및
              이용목적이 달성되거나 보유 및 이용기간이 종료되면 해당 개인정보를
              즉시 파기합니다. 단, 관계법령의 규정에 의하여 보존할 필요가 있는
              경우 “(주)엠에이디씨”는 관계법령에서 정한 일정한 기간 동안
              여러분의 정보를 안전하게 보관합니다
              <br />. 이 경우 보관하는 정보를 그 보관의 목적으로만 이용하며
              보존기간은 아래와 같습니다. 보존항목 보존근거 보존기간 이름,
              이동전화번호, 성별, 생년월일, 암호화된 동일인 식별정보 (CI),
              중복가입 식별정보 (DI), 주소, 이메일(E-mail), 사업자번호, 기업명,
              내외국인 정보, 통신사, 단말기 모델명 서비스 이용의 혼선방진,
              불법적 사용자에 대한 관련 기관의 수사에 협조 계약종료 후 1년
              <br /> ③ 관계 법령의 규정에 따라 보존할 필요가 있으면
              (주)엠에이디씨는 아래와 같이 관계 법령에서 정한 기간 동안
              회원정보를 보관합니다. 보존항목 보존근거 보존기간 서비스 이용기록,
              접속 로그, 접속 아이피(IP) 정보 통신비밀보호법 3개월 표시/광고에
              관한 기록 전자상거래 등에서의 소비자보호에 관한 법률 6개월 계약
              또는 청약철회 등에 관한 기록 전자상거래 등에서의 소비자보호에 관한
              법률 5년
            </BasicText>
            <StyleSubTitle>7. 회원의 권리와 그 행사방법</StyleSubTitle>
            <BasicText bold={400} color={Colors.gray4}>
              이용자 및 법정 대리인은 언제든지 등록되어 있는 자신의 개인정보를
              조회하거나 수정할 수 있으며 가입해지를 요청할 수도 있습니다.
              이용자는 개인정보 조회, 수정을 위해서는 ‘개인정보변경’(또는
              ‘회원정보수정’등)을 가입해지(동의철회)를 위해서는 “회원탈퇴”를
              클릭하여 본인 확인 절차를 거치신 후 직접 열람, 정정 또는 탈퇴가
              가능합니다.
              <br /> 혹은 개인정보관리책임자에게 서면, 전화 또는 이메일로
              연락하시면 지체없이 조치하겠습니다. 귀하가 개인정보의 오류에 대한
              정정을 요청하신 경우에는 정정을 완료하기 전까지 당해 개인정보를
              이용 또는 제공하지 않습니다. 또한 잘못된 개인정보를 제3자에게 이미
              제공한 경우에는 정정 처리결과를 제3자에게 지체없이 통지하여 정정이
              이루어지도록 하겠습니다.
              <br /> (주)엠에이디씨는 이용자 혹은 법정 대리인의 요청에 의해 해지
              또는 삭제된 개인정보는 “(주)엠에이디씨가 수집하는 개인정보의 보유
              및 이용기간”에 명시된 바에 따라 처리하고 그 외의 용도로 열람 또는
              이용할 수 없도록 처리하고 있습니다.{" "}
            </BasicText>
            <StyleSubTitle>8. 아동의 개인정보 보호</StyleSubTitle>
            <BasicText bold={400} color={Colors.gray4}>
              “(주)엠에이디씨”는 "정보통신망이용 촉진 및 정보보호등에 관한 법률
              제31조 제1항"에 의하여 만14세 미만의 아동의 개인정보 수집 시
              법정대리인의 동의를 받아야 합니다. 따라서, “(주)엠에이디씨”는
              만14세 미만의 아동에 대해서는 개인정보를 받지 않을 뿐만 아니라,
              고객으로 가입이 되지 않습니다.
            </BasicText>
            <StyleSubTitle>9. 회원의 의무</StyleSubTitle>{" "}
            <BasicText bold={400} color={Colors.gray4}>
              ① “회원”은 개인정보를 최신의 상태로 정확하게 입력하여야 합니다.
              회원 여러분이 입력한 부정확한 정보로 인하여 발생하는 문제의 책임은
              회원 여러분 자신에게 있습니다.
              <br /> ② “회원”은 개인정보를 보호받을 권리와 함께 스스로를
              보호하고 타인의 정보를 침해하지 않을 의무도 가지고 있습니다. 회원
              여러분 자신의 개인정보가 유출되지 않도록 주의를 기울여야 하며,
              게시물을 포함한 타인의 개인정보를 훼손하지 않도록 유의하여야
              합니다. 만약, 이용자가 이와 같은 책임을 다하지 못하고 타인의
              개인정보 및 존엄성을 훼손할 시에는 회원자격 상실과 함께 관련
              법령에 의하여 처벌받을 수 있습니다{" "}
            </BasicText>
            <StyleSubTitle>
              10. 개인정보 자동 수집 장치의 설치 /운영 및 그 거부에 관한 사항
            </StyleSubTitle>{" "}
            <BasicText bold={400} color={Colors.gray4}>
              ① “(주)엠에이디씨”는 회원 여러분에게 개인화되고 맞춤화 된 서비스를
              제공하기 위해 회원 여러분의 정보를 수시로 저장하고 불러오는
              '쿠키(Cookie)'를 사용합니다. 쿠키란 “(주)엠에이디씨”의 웹사이트를
              운영하는데 이용되는 서버가 회원 여러분의 컴퓨터로 전송하는 아주
              작은 텍스트 파일로서 회원 여러분의 컴퓨터 하드디스크에 저장됩니다.
              쿠키는 개인을 식별하는 정보를 자동적/능동적으로 수집하지 않으며,
              회원 여러분은 언제든지 이러한 쿠키의 저장을 거부하거나 삭제할 수
              있습니다.
              <br /> ② 쿠키설정 거부 방법 회원 여러분이 사용하는 웹 브라우저의
              옵션을 설정함으로써 모든 쿠키를 허용하거나 쿠키를 저장할 때마다
              확인을 거치거나, 모든 쿠키의 저장을 거부할 수 있습니다. 다만
              쿠키의 저장을 거부할 경우에는 로그인이 필요한 부분 등 서비스의
              일부의 이용에 어려움이 있을 수 있습니다.
            </BasicText>
            <StyleSubTitle>
              11. 개인정보의 기술적, 관리적 보호 대책
            </StyleSubTitle>{" "}
            <BasicText bold={400} color={Colors.gray4}>
              (주)엠에이디씨는 이용자들의 개인정보를 취급함에 있어 개인정보가
              분실, 도난, 누출, 변조 또는 훼손되지 않도록 안전성 확보를 위하여
              다음과 같은 기술적 대책을 강구하고 있습니다. 이용자들의 개인정보는
              비밀번호에 의해 철저히 보호되고 있습니다. 하나팩스 회원
              아이디(ID)의 비밀번호는 본인만이 알고 있으며, 개인정보의 확인 및
              변경도 비밀번호를 알고 있는 본인에 의해서만 가능합니다. 따라서
              이용자 여러분께서는 비밀번호를 누구에게도 알려주시면 안됩니다.
              <br />
              <br /> 이를 위해 (주)엠에이디씨에서는 기본적으로 PC에서의 사용을
              마치신 후 온라인상에서 로그아웃(LOG-OUT)하시고 웹브라우저를
              종료하도록 권장합니다.
              <br />
              <br /> 특히 다른 사람과 PC를 공유하여 사용하거나
              공공장소((주)엠에이디씨나 학교, 도서관, 인터넷 게임방 등)에서
              이용한 경우에는 개인정보가 다른 사람에게 알려지는 것을 막기 위해
              위와 같은 절차가 더욱 필요할 것입니다. (주)엠에이디씨는 해킹이나
              컴퓨터 바이러스 등에 의해 회원의 개인정보가 유출되거나 훼손되는
              것을 막기 위해 최선을 다하고 있습니다. 개인정보의 훼손에 대비해서
              자료를 수시로 백업하고 있고, 최신 백신프로그램을 이용하여
              이용자들의 개인정보나 자료가 누출되거나 손상되지 않도록 방지하고
              있으며, 암호알고리즘 등을 통하여 네트워크상에서 개인정보를
              안전하게 전송할 수 있도록 하고 있습니다.
              <br />
              <br />
              그리고 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고
              있으며, 기타 시스템적으로 안정성을 확보하기 위한 가능한 모든
              기술적 장치를 갖추려 노력하고 있습니다. (주)엠에이디씨의
              개인정보관련 취급 직원은 담당자에 한정시키고 있고 이를 위한 별도의
              비밀번호를 부여하여 정기적으로 갱신하고 있으며, 담당자에 대한 수시
              교육을 통하여 하나팩스의 개인정보 보호정책의 준수를 항상 강조하고
              있습니다.
              <br />
              <br /> 기술적 대책 당사는 귀하의 개인정보를 취급함에 있어
              개인정보가 분실, 도난, 누출, 변조 또는 훼손되지 않도록 안전성
              확보를 위하여 다음과 같은 기술적 대책을 강구하고 있습니다.
              <br /> 1) 귀하의 개인정보는 비밀번호에 의해 보호되며 파일 및
              전송데이터를 암호화하거나 파일 잠금기능(Lock)을 사용하여 중요한
              데이터는 별도의 보안기능을 통해 보호되고 있습니다.
              <br /> 2) 당사는 백신프로그램을 이용하여 컴퓨터바이러스에 의한
              피해를 방지하기 위한 조치를 취하고 있습니다. 백신프로그램은
              주기적으로 업데이트되며 갑작스런 바이러스가 출현할 경우 백신이
              나오는 즉시 이를 제공함으로써 개인정보가 침해되는 것을 방지하고
              있습니다.
              <br /> 3) 당사는 암호알고리즘을 이용하여 네트워크상의 개인정보를
              안전하게 전송할 수 있는 보안장치(SSL)를 채택하고 있습니다.
              <br /> 4) 해킹 등 외부침입에 대비하여 각 서버마다 침입차단시스템
              및 취약점 분석시스템 등을 이용하여 보안에 만전을 기하고 있습니다.
              <br /> ② 관리적 대책
              <br /> 1) 당사는 귀하의 개인정보에 대한 접근권한을 최소한의
              인원으로 제한하고 있습니다. 그 최소한의 인원에 해당하는 자는
              다음과 같습니다. • 이용자를 직접 상대로 하여 마케팅 업무를
              수행하는 자 • 개인정보관리책임자 및 담당자 등 개인정보관리업무를
              수행하는 자 • 기타 업무상 개인정보의 취급이 불가피한 자<br /> 2)
              개인정보를 취급하는 직원을 대상으로 새로운 보안 기술 습득 및
              개인정보 보호 의무 등에 관해 정기적인 사내 교육 및 외부 위탁교육을
              실시하고 있습니다.
              <br />
              3) 입사 시 전 직원의 보안서약서를 통하여 사람에 의한 정보유출을
              사전에 방지하고 개인정보보호정책에 대한 이행사항 및 직원의
              준수여부를 감사하기 위한 내부절차를 마련하고 있습니다.
              <br /> 4) 개인정보 관련 취급자의 업무 인수인계는 보안이 유지된
              상태에서 철저하게 이뤄지고 있으며 입사 및 퇴사 후 개인정보 사고에
              대한 책임을 명확화하고 있습니다.
              <br /> 5) 개인정보와 일반 데이터를 혼합하여 보관하지 않고 별도의
              서버를 통해 분리하여 보관하고 있습니다.
              <br /> 6) 전산실 및 자료 보관실 등을 특별 보호구역으로 설정하여
              출입을 통제하고 있습니다.
              <br /> 7) 당사는 이용자 개인의 실수나 기본적인 인터넷의 위험성
              때문에 일어나는 일들에 대해 책임을 지지 않습니다. 회원 개개인이
              본인의 개인정보를 보호하기 위해서 자신의 ID 와 비밀번호를 적절하게
              관리하고 여기에 대한 책임을 져야 합니다.
              <br /> 8) 그 외 내부 관리자의 실수나 기술관리 상의 사고로 인해
              개인정보의 상실, 유출, 변조, 훼손이 유발될 경우 당사는 즉각 귀하께
              사실을 알리고 적절한 대책과 보상을 강구할 것입니다.{" "}
            </BasicText>
            <StyleSubTitle>12. 개인정보에 관한 민원서비스</StyleSubTitle>
            <BasicText bold={400} color={Colors.gray4}>
              ① “(주)엠에이디씨”는 회원 여러분의 개인정보를 보호하고 개인정보와
              관련한 불만을 처리하기 위하여 아래와 같이 관련 부서와
              개인정보관리책임자를 지정하고 있습니다. 구분 개인정보관리책임자
              개인정보관리담당자 성명 및 직책 김홍민 대표이사 이메일
              1105hm@naver.com 연락처 02-6925-5866
              <br />② “회원”은 서비스를 이용하는 과정에서 발생하는 모든
              개인정보보호 관련 민원을 개인정보관리책임자 혹은 담당 부서로
              신고하실 수 있습니다. “(주)엠에이디씨”는 여러분의 신고 사항에 대해
              신속하게 충분한 답변을 드릴 것입니다. 이 밖에 개인정보침해에 대해
              신고나 상담이 필요하면 아래 기관에 문의하시기 바랍니다.
              <br />
              A. 개인정보침해신고센터: http://privacy.kisa.or.kr / 국번없이 118
              <br /> B. 대검찰청 사이버범죄수사단: cybercid@spo.go.kr /
              02-3480-3571
              <br /> C. 경찰처 사이버테러대응센터: http://www.netan.go.kr /
              1566-0112
            </BasicText>
            <StyleSubTitle>13. 고지의 의무 현 개인정보처리방침</StyleSubTitle>
            <BasicText bold={400} color={Colors.gray4}>
              2024년 2월 05일부터 적용되며, 법령, 정책 또는 보안기술의 변경에
              따라 내용의 추가, 삭제 및 수정이 있을 시에는 변경사항 시행일의 7일
              전부터 “(주)엠에이디씨”의 웹사이트를 통해 고지할 것입니다.
            </BasicText>
          </div>
          {/* <Button
            onClick={() => nvaigate(-1)}
            style={{
              marginTop: 20,
              width: 200,
            }}
          >
            확인
          </Button> */}
        </div>
      </StyledMainContainer>
    </>
  );
};

export default Information;

const StyledMainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .text-area {
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }
`;
