import React from 'react'
import {Space, Spin} from 'antd'
import {useIsFetching} from 'react-query'

const Loading: React.FC = () => {
  const isFetching = useIsFetching()
  // const isMutating = useIsMutating()

  const display = isFetching ? true : false

  return (
    <Space
      direction="vertical"
      style={{
        zIndex: 1000,
        position: 'absolute',
        top: '50%',
        left: '50%',
      }}
    >
      <Space>
        <Spin tip="Loading" size="large" spinning={display}>
          <div className="content" />
        </Spin>
      </Space>
    </Space>
  )
}

export default Loading
