import React, { useEffect, useState } from "react";
import styled from "styled-components";
import gif1 from "../../assets/img/gif/1.gif";
import gif2 from "../../assets/img/gif/2.gif";
import gif3 from "../../assets/img/gif/3.gif";
import gif4 from "../../assets/img/gif/4.gif";
import logo from "../../assets/img/f5_logo.png";
import logo2 from "../../assets/img/f5_logo2.png";
import logo3 from "../../assets/img/f5_logo_test.png";
import logo4 from "../../assets/img/f5_logo_text.png";
import BasicText from "../../components/atomics/Text/BasicText";
import { FloatButton } from "antd";
import { InstagramOutlined } from "@ant-design/icons";

const MainPage = () => {
  return (
    <StyledMain>
      <div className="header">
        <div>
          <img src={logo3} width={50} />
        </div>
        <div
          style={{
            textAlign: "center",
            display: "flex",
          }}
        >
          <BasicText bold={700} size={40} lineHeight={0}>
            새로고침
          </BasicText>
        </div>
      </div>
      <div className="video1">
        <img src={gif1} alt="frame1" width={"100%"} />
      </div>
      <div className="video2">
        <img src={gif2} alt="frame1" width={"100%"} />
      </div>
      <div className="video3">
        <img src={gif3} alt="frame1" width={"100%"} />
      </div>

      <div className="video4">
        <img src={gif4} alt="frame1" width={"100%"} />
      </div>
      <div className="textCenter">
        <BasicText bold={700} size={40}>
          새로고침이란?
        </BasicText>
      </div>
    </StyledMain>
  );
};

export default MainPage;

const StyledMain = styled.div`
  height: 100vh;
  display: flex;
  // justify-content: center;
  align-items: flex-start;
  position: relative;
  background: radial-gradient(
    ellipse 80% 50% at center,
    #cce7ff 10%,
    #f0f9ff 80%
  ); /* 중앙으로 모이는 그라데이션 */
  overflow: hidden;

  .header {
    display: flex;
    align-items: center;
    text-align: center;
    height: 10vh;
    padding: 40px 0 0 40px;
    gap: 0.2em;
  }

  .video {
    position: absolute;
  }

  /* video2는 가장 하단에 위치하면서 흐릿하게 처리 */
  .video2 {
    z-index: 2; /* background보다 위에 있지만 video1,3,4보다 낮음 */
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    position: absolute;
    opacity: 0.5; /* 투명도 적용 */
    filter: blur(1.5px); /* 흐림 효과 */
    width: 170px;
    top: 5%;
    right: 25%;
  }

  .video4 {
    z-index: 2; /* background보다 위에 있지만 video1,3,4보다 낮음 */
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    position: absolute;
    opacity: 0.5; /* 투명도 적용 */
    filter: blur(1.5px); /* 흐림 효과 */
    width: 170px;
    top: 55%;
    left: 25%;
  }

  /* 배경을 video2 뒤에 두고 흐림 효과 적용 */
  .background {
    position: absolute;
    z-index: 1; /* video2보다 아래에 배치 */
    width: 100vw;
    height: 100vh;
    background: radial-gradient(
      circle,
      #ffffff80,
      #f0f9ff
    ); /* 약간 투명한 배경 */
    filter: blur(4px); /* 배경 흐림 효과 */
  }

  /* video1, video3, video4는 최상단에 배치 */
  .video1,
  .video3 {
    z-index: 3; /* 가장 높은 z-index */
    opacity: 0.9; /* 약간 투명하게 */
    width: 170px;
  }

  .video1 {
    position: absolute;
    top: 12%;
    left: 10%;
  }

  .video3 {
    position: absolute;
    bottom: 12%;
    right: 10%;
  }

  .video1 img,
  .video2 img,
  .video3 img,
  .video4 img {
    border-radius: 14px;
  }

  .textCenter {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: black;
    z-index: 5; /* 모든 비디오 위에 텍스트 표시 */
    width: 100%;
  }

  @media (max-width: 480px) {
    .video1 img,
    .video3 img {
      display: none;
    }

    .video2 img {
      position: absolute;
      top: 8%;
      left: 25%;
      // max-height: 300px;
      width: 120px;
    }

    .video4 img {
      position: absolute;
      top: 0%;
      max-height: 350px;
      left: 0%;
      // right: 50%;
      width: 120px;
    }
  }
`;
