import { Button, Row } from "antd";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import PrivacyHeader from "./PrivacyHeader";
import BasicText from "../../components/atomics/Text/BasicText";
import Colors from "../../constants/Colors";
import Link from "antd/es/typography/Link";

// 개인정보 수집·이용 동의
const PrivacyCollect = () => {
  const nvaigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <PrivacyHeader homeIcon />
      <StyledMainContainer>
        <div className="text-area">
          <h1>
            <BasicText bold={700} size={23} color={Colors.primary}>
              (주)엠에이디씨 개인정보 수집·이용 동의
            </BasicText>
          </h1>
          <br />
          <div>
            <BasicText bold={700} size={20}>
              1. (주)엠에이디씨 개인정보 수집
            </BasicText>
            <br />
            <BasicText bold={400} color={Colors.gray4}>
              (주)엠에이디씨는 회원제 서비스로 운영되며 회원 가입 시 또는 서비스
              이용 과정에서 홈페이지, 어플리케이션 프로그램 등을 통해 서비스
              제공을 위해 필요한 최소한의 개인정보를 수집합니다.(주)엠에이디씨
              서비스를 이용하기 위해 이용자로부터 수집하는 개인정보는 아래와
              같습니다.
              <br />
              {/* graph 필요 */}
              [서비스 별 개인정보 수집 항목] 서비스 구분 수집항목 (개인)회원가입
              (이메일) [필수] 이메일 주소/비밀번호 (개인)회원가입(카카오) [필수]
              프로필 정보(닉네임/프로필 사진) (개인)회원가입(네이버) [필수]
              이용자 식별값 (개인)회원가입(메타) [필수] 이름 및 프로필 사진
              (개인)회원가입(애플) [필수] 이용자 식별값 제휴/병원 입점 문의
              [필수] 업체명/담당자명/휴대전화번호/이메일 병원 입점 계약
              (병원회원) 신청자 [필수]
              담당자명/대표자명/휴대전화번호/이메일/주소/대표번호 신청정보
              [필수] 병원명/담당자명/휴대전화번호/이메일/관리자ID 본인인증 시
              (PASS) [필수] 이름/휴대전화번호/생년월일/성별 콘텐츠 이용 시
              시술/부작용 후기 (민감정보) [필수] 시술 정보/시술 후 사진 [선택]
              시술 전 사진 게시중단 요청 서비스 (본인 확인 서류) 개인 [필수]
              성명/휴대전화번호/이메일/마스킹 된 신분증 사본/위임장 단체 [필수]
              성명/휴대전화번호/이메일/마스킹 된 신분증 사본/단체 사업자 등록증
              사본/위임장 위치기반 서비스 이용 시 위치정보 <br />
              <br />
              [개인정보 수집 방법]
              <br />
              <br /> (주)엠에이디씨는 개인정보를 수집하는 경우 사전에 이용자에게
              해당 을 알리고 동의를 구하고 있으며, 수집 방법은 아래와 같습니다.
              <br /> - 회원가입 및 서비스 이용 과정에서 이용자가 개인정보 수집에
              대한 동의를 하고 직접 정보를 입력하는 경우에 수집합니다.
              <br />- CS를 통한 상담 과정에서 웹페이지, 메일, 팩스, 유선 전화
              등을 통해 수집 될 수 있습니다.
              <br />- 온/오프라인에서 진행되는 이벤트, 세미나 등을 통한 개인정보
              수집 될 수 있습니다.
              <br />- 기기정보와 같은 생성정보는 PC웹, 모바일 웹/앱 이용
              과정에서 자동으로 생성되어 수집 됩니다.
              <br /> <br />
              [자동 수집 및 생성 정보]
              <br />
              <br /> 서비스 이용 과정에서 이용자로부터 자동으로 생성 수집하는
              개인정보는 아래와 같습니다.
              <br /> - PC웹, 모바일 웹/앱 이용 과정에서 단말기 정보(OS,
              디바이스아이디, 폰기종, 단말기 모델명), IP주소, 쿠키, 방문일시,
              부정이용기록, 서비스 이용 기록 등 <br />
              <br />
            </BasicText>
            <BasicText bold={700} size={20}>
              2. 개인정보의 이용
            </BasicText>

            <br />
            <BasicText bold={400} color={Colors.gray4}>
              (주)엠에이디씨 및 (주)엠에이디씨 관련 제반 서비스(모바일 웹/앱
              포함)의 회원관리, 서비스 개발·제공 및 향상, 안전한 인터넷 이용환경
              구축 등 아래의 목적으로만 개인정보를 이용합니다.
              <br /> - 회원 식별 및 가입의사 확인, 본인/연령 확인, 이용자 식별
              등 회원 관리
              <br />- 문의사항 또는 불만처리, 공지사항 전달
              <br />- 유료서비스 이용 시 콘텐츠 등의 전송이나 요금 정산
              <br />- 서비스의 원활한 운영에 지장을 주는 행위(계정 도용 및 부정
              이용 행위 등 포함)에 대한 방지 및 제제
              <br />- 인구통계학적 특성과 이용자의 관심, 기호, 성향의 추정을
              통한 맞춤형 콘텐츠 추천 및 이벤트, 광고 등 마케팅에 활용
              <br />- 신규 서비스 개발 및 서비스 개선, 프라이버시 보호를 위한
              서비스 환경 구축
              <br />- 서비스 이용 기록, 접속 빈도 및 서비스 이용에 대한 통계
              <br />
              <br />
            </BasicText>
            <BasicText bold={700} size={20}>
              3. 개인정보 보유·이 용 기간
            </BasicText>
            <BasicText bold={400} color={Colors.gray4}>
              <br />
              - 회원 탈퇴 후 30일까지(상담 등 민원처리 및 중복 회원가입,
              부정이용방지)
              <br />- 관계법령에 따라 보존할 필요가 있는 경우 해당 법령에서
              요구하는 기간까지 보관 <br />
              <br />※ 위와 같은 개인정보 수집·이용에 동의하지 않을 수 있으나, 이
              경우 (주)엠에이디씨 회원가입이 제한됩니다.
              <br />
            </BasicText>
            <br />
            <Link href="/privacypolicy">개인정보처리방침 전문보기</Link>
          </div>
          {/* <Button
            onClick={() => nvaigate(-1)}
            style={{
              marginTop: 20,
              width: 200,
            }}
          >
            확인
          </Button> */}
        </div>
      </StyledMainContainer>
    </>
  );
};

export default PrivacyCollect;

const StyledMainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .text-area {
    // width: 60%;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }
`;
