import { Button } from "antd";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import BasicText from "../../components/atomics/Text/BasicText";
import Colors from "../../constants/Colors";
import PrivacyHeader from "./PrivacyHeader";

export interface StyleTitleProps {
  children: React.ReactNode; // ReactNode 타입은 JSX를 포함할 수 있는 모든 타입을 포함합니다.
}

// 개인정보 처리방침
const PrivacyPolicy = () => {
  const nvaigate = useNavigate();

  const StyleTitle: React.FC<StyleTitleProps> = ({ children }) => (
    <>
      <br />
      <br />
      <BasicText bold={700} size={23} color={Colors.primary}>
        {children}
      </BasicText>
      <br />
      <br />
    </>
  );

  const StyleSubTitle: React.FC<StyleTitleProps> = ({ children }) => (
    <>
      <br />
      <br />
      <br />
      <BasicText bold={700} size={18} color={Colors.text}>
        {children}
      </BasicText>
      <br />
      <br />
    </>
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <PrivacyHeader homeIcon />

      <StyledMainContainer>
        <div className="text-area">
          <StyleTitle>개인정보 처리방침</StyleTitle>

          <div>
            <BasicText bold={400} color={Colors.gray4}>
              주식회사 (주)엠에이디씨(이하 ‘(주)엠에이디씨’ 또는 회사’)는
              이용자의 동의를 기반으로 개인정보를 수집·이용 및 제공하고
              있습니다. (주)엠에이디씨는 이용자의 권리를 보장하고
              개인정보처리자가 준수하여야하는 국내 관계 법령 및 개인정보보호
              규정, 가이드라인을 준수하여 개인정보 처리방침을 제공합니다.
            </BasicText>
            <StyleSubTitle>1. 개인정보의 수집</StyleSubTitle>{" "}
            <BasicText bold={400} color={Colors.gray4}>
              (주)엠에이디씨는 회원제 서비스로 운영되며 회원 가입 시 또는 서비스
              이용 과정에서 홈페이지, 어플리케이션 프로그램 등을 통해 서비스
              제공을 위해 필요한 최소한의 개인정보를 수집합니다. (주)엠에이디씨
              서비스를 이용하기 위해 이용자로부터 수집하는 개인정보는 아래와
              같습니다.
            </BasicText>
            <br />
            <br />
            <BasicText bold={400} color={Colors.gray4}>
              [서비스 별 개인정보 수집 항목]
            </BasicText>
            <br />
            <br />
            {/* Todo Table 개인정보 처리 방침 */}
            <BasicText bold={400} color={Colors.gray4}>
              서비스 구분 수집항목 (개인)회원가입 (이메일) [필수] 이메일
              주소/비밀번호 (개인)회원가입(카카오) [필수] 프로필
              정보(닉네임/프로필 사진) (개인)회원가입(네이버) [필수] 이용자
              식별값 (개인)회원가입(메타) [필수] 이름 및 프로필 사진
              (개인)회원가입(애플) [필수] 이용자 식별값 제휴/병원 입점 문의
              [필수] 업체명/담당자명/휴대전화번호/이메일 병원 입점 계약
              (병원회원) 신청자 [필수]
              담당자명/대표자명/휴대전화번호/이메일/주소/대표번호 신청정보
              [필수] 병원명/담당자명/휴대전화번호/이메일/관리자ID 본인인증 시
              (PASS) [필수] 이름/휴대전화번호/생년월일/성별 콘텐츠 이용 시
              시술/부작용 후기 (민감정보) [필수] 시술 정보/시술 후 사진 [선택]
              시술 전 사진 게시중단 요청 서비스 (본인 확인 서류) 개인 [필수]
              성명/휴대전화번호/이메일/마스킹 된 신분증 사본/위임장 단체 [필수]
              성명/휴대전화번호/이메일/마스킹 된 신분증 사본/단체 사업자 등록증
              사본/위임장 위치기반 서비스 이용 시 위치정보
              <br />
              <br />
              [개인정보 수집 방법]
              <br />
              <br />
              (주)엠에이디씨는 개인정보를 수집하는 경우 사전에 이용자에게 해당
              사실을 알리고 동의를 구하고 있으며, 수집 방법은 아래와 같습니다.
              <br /> - 회원가입 및 서비스 이용 과정에서 이용자가 개인정보 수집에
              대한 동의를 하고 직접 정보를 입력하는 경우에 수집합니다.
              <br /> - CS를 통한 상담 과정에서 웹페이지, 메일, 팩스, 유선 전화
              등을 통해 수집 될 수 있습니다.
              <br /> - 온/오프라인에서 진행되는 이벤트, 세미나 등을 통한
              개인정보 수집 될 수 있습니다.
              <br /> - 기기정보와 같은 생성정보는 PC웹, 모바일 웹/앱 이용
              과정에서 자동으로 생성되어 수집 됩니다.
              <br />
              <br />
              [자동 수집 및 생성 정보]
              <br />
              <br />
              서비스 이용 과정에서 이용자로부터 자동으로 생성 수집하는
              개인정보는 아래와 같습니다.
              <br />
              <br /> - PC웹, 모바일 웹/앱 이용 과정에서 단말기 정보(OS,
              디바이스아이디, 폰기종, 단말기 모델명), IP주소, 쿠키, 방문일시,
              부정이용기록, 서비스 이용 기록 등
            </BasicText>
            <StyleSubTitle>2. 개인정보의 이용</StyleSubTitle>
            <BasicText bold={400} color={Colors.gray4}>
              (주)엠에이디씨 및 (주)엠에이디씨 관련 제반 서비스(모바일 웹/앱
              포함)의 회원관리, 서비스 개발·제공 및 향상, 안전한 인터넷 이용환경
              구축 등 아래의 목적으로만 개인정보를 이용합니다.
              <br /> - 회원 식별 및 가입의사 확인, 본인/연령 확인, 이용자 식별
              등 회원 관리
              <br /> - 문의사항 또는 불만처리, 공지사항 전달
              <br /> - 유료서비스 이용 시 콘텐츠 등의 전송이나 요금 정산
              <br /> - 서비스의 원활한 운영에 지장을 주는 행위(계정 도용 및 부정
              이용 행위 등 포함)에 대한 방지 및 제제
              <br /> - 인구통계학적 특성과 이용자의 관심, 기호, 성향의 추정을
              통한 맞춤형 콘텐츠 추천 및 이벤트, 광고 등 마케팅에 활용
              <br /> - 신규 서비스 개발 및 서비스 개선, 프라이버시 보호를 위한
              서비스 환경 구축
              <br /> - 서비스 이용 기록, 접속 빈도 및 서비스 이용에 대한 통계
            </BasicText>
            <StyleSubTitle>3. 개인정보의 제공 및 위탁</StyleSubTitle>{" "}
            <BasicText bold={400} color={Colors.gray4}>
              개인정보 제3자 제공
              <br /> - (주)엠에이디씨는 이용자의 별도 동의가 있거나 법령에
              규정된 경우를 제외하고는 이용자의 개인정보를 제3자에게 제공하지
              않습니다.
              <br />
              <br />
              개인정보 처리 위탁
              <br /> - (주)엠에이디씨는 서비스 제공을 위해 필요한 경우 개인정보
              처리 업무 중 일부를 외부에 위탁 할 수 있습니다. 개인정보 안전성
              확보조치 및 재위탁 제한 등 위탁받은 업체의 개인정보 보호 관련 법령
              준수 여부를 관리·감독하고 있습니다.
              <br />
              <br />
              [개인정보 처리 위탁 현황_국내]
              <br />
              {/* Table 필요 */}
              수탁업체 위탁업무 내용 SKB, 가비아 SMS 발송 서비스 애드저스트
              코리아 제휴사 맞춤 광고 서비스 개발 및 제공 드림시큐리티
              본인인증(PASS) 서비스 Amazon Web Service 시스템 제공 및 관리
              카카오 SNS 가입 여부 및 계정 정보 확인, 맞춤 광고 서비스 네이버
              SNS 가입 여부 및 계정 정보 확인 페이스북 SNS 가입 여부 및 계정
              정보 확인 애플 SNS 가입 여부 및 계정 정보 확인 구글(구글애즈) 고객
              맞춤형 광고 제공 CRITEO 고객 맞춤형 광고 제공 아임포트 결제 API
              서비스 제공 이니시스 PG 결제서비스 제공 현대해상 부작용 안전보장
              서비스 제공 핵클 서비스 이용 통계 및 분석 ㈜써머스플랫폼 카카오
              비즈 메시지 서비스 제공 헥토파이낸셜 가상계좌 발급 서비스 제공
              바이브알씨 (주)엠에이디씨 영업 업무 위탁 애드플라이어
              (주)엠에이디씨 영업 업무 위탁 MPR (주)엠에이디씨 영업 업무 위탁
              위카 (주)엠에이디씨 영업 업무 위탁 한강 (주)엠에이디씨 영업 업무
              위탁 트리니티랩스 (주)엠에이디씨 영업 업무 위탁 와커스
              (주)엠에이디씨 영업 업무 위탁 파이메이커스 (주)엠에이디씨 영업
              업무 위탁
              <br />
              <br />
              {/* [개인정보 처리 위탁 현황_국외]
              <br />
              수탁업체 이전 목적 위탁 개인정보 항목 위탁 일시 및 방법 업체위치
              (국가) 연락처 개인정보 이용기간 구글 서비스 이용 통계 및 분석
              성별, 지역, 가입일자, 앱 내 행동로그 서비스 이용 시점에 네트워크를
              통한 전송 미국 (1600 Amphitheatre Parkway Mountain View,
              California 94043 USA) google_da_kr@dagent.co.kr 본 개인정보
              처리방침에 규정된 보관기간과 일치함 센드버드 채팅서비스 이용 및
              보관 성별, 채팅메시지 서비스 이용 시점에 네트워크를 통한 전송
              미국(400 1st Ave,San Mateo, CA 94401) Legal@senbird.com 앰플리튜드
              서비스 이용 통계 및 분석 IP, 성별, 지역, 가입일자, 앱내 행동로그
              서비스 이용 시점에 네트워크를 통한 전송 미국 (Amplitude SF, 631
              Howard St. San Francisco, CA 94105) Privacy@amplitude.com 통계
              추출 후 즉시 삭제 브레이즈 맞춤 CRM 메시지 발송(앱 푸시 등) 및
              모바일 알림 서비스 제공 자동 수집 정보, 디바이스 정보, 마케팅 수신
              동의 여부 서비스 이용 시점에 네트워크를 통한 전송 미국(General
              Counsel 330 West 34th Street, 18th Floor New York, NY 10001)
              Privacy@braze.com AWS 서비스 이용 통계 및 분석 앱 이용 정보 통계
              추출 시점에 네트워크를 통한 전송 일본(도쿄리전)
              <br /> - 이용자는 회사의 개인정보보호책임자 및 담당부서를 통해
              개인정보의 국외이전을 거부할 수 있습니다.
              <br /> - 이용자가 개인정보의 국외 이전을 거부하는 경우 회사는 해당
              이용자의 개인정보를 국외 이전 대상에서 제외합니다. 이 경우회사의
              서비스 중 개인정보 국외 이전이 필수적으로 수반되는 서비스의 이용이
              제한될 수 있습니다. */}
            </BasicText>
            <StyleSubTitle>4. 개인정보의 파기</StyleSubTitle>
            <BasicText bold={400} color={Colors.gray4}>
              (주)엠에이디씨는 개인정보의 수집 및 이용목적이 달성된 경우 수집한
              개인정보를 지체없이 파기하며 절차 및 방법은 아래와 같습니다.
              <br /> - 수집 및 이용 목적의 달성 또는 회원 탈퇴 등 파기 사유가
              발생한 경우, 개인정보의 형태를 고려하여 파기 방법을 정합니다.
              전자적 파일 형태인 경우 복구 및 재생되지 않도록 안전하게 삭제하고,
              그 밖에 기록물, 인쇄물 서면 등의 경우 분쇄하거나 소각하여
              파기합니다.
            </BasicText>
            <br />
            <br />
            <BasicText bold={400} color={Colors.gray4}>
              [내부 방침에 따라 일정기간 보관 후 파기하는 정보]
            </BasicText>
            <BasicText bold={400} color={Colors.gray4}>
              <br /> - 회원 탈퇴 시 30일간 개인정보 보관 후 파기
              <br /> - 대리수술 안심존 신청 정보 : 안심존 신청 철회 전까지 보관
              후 파기
              <br /> - 병원 입점 신청 정보 : 입점 신청 상담 종료 후 즉시 파기
              <br /> - 병원 입점 계약 정보 : 위탁 계약 종료 시 즉시 파기
              <br /> - 상담 신청 정보 : 상담 종료 후 1년간 보관 후 파기
              <br /> - 어뷰징 및 부정 이용 기록 : 3년간 보관 후 파기
              <br />
              <br />
              [관계법령에 따른 개인정보 보관]
              <br />
              {/* Table 위탁 관계법령에 따른 필요 */}
              보존 항목 근거법령 보존기간 계약 또는 청약철회 등에 관한 기록
              전자상거래 등에서의 소비자 보호에 관한 법률 국세기본법 5년
              대금결제 및 재화 등의 공급에 관한 기록 5년 소비자의 불만 또는
              분쟁처리에 관한 기록 3년 표시/광고에 관한 기록 6개월 세법이
              규정하는 모든 거래에 관한 장부 및 증빙서류 5년 전자금융 거래에
              관한 기록 전자금융거래법 5년 서비스 방문 기록 통신비밀보호법 3개월
            </BasicText>
            <StyleSubTitle>5. 이용자 및 법적대리인의 권리</StyleSubTitle>
            <BasicText bold={400} color={Colors.gray4}>
              이용자 또는 법정대리인은 회사에 대해 언제든지 자신의 개인정보 보호
              관련 권리를 행사할 수 있습니다. 이용자 또는 법정대리인은 회사의
              개인정보 처리에 동의하지 않는 경우 동의 철회 혹은 회원 탈퇴를
              요청할 수 있습니다. 단, 이 경우 서비스의 일부 또는 전부의 이용이
              어려울 수 있습니다. <br />① 개인정보 조회, 수정을 위해서는
              "개인정보변경" (또는 '회원정보수정' 등)을, 회원탈퇴를 위해서는
              앱에서 제공하는 "회원탈퇴" 기능을 이용하여 탈퇴가 가능합니다.
              <br /> ② 이용자가 개인정보의 오류에 대한 정정을 요청하는 경우에는
              정정을 완료하기 전까지 당해 개인정보를 이용 또는 제공하지
              않습니다. 또한 잘못된 개인정보를 제3자에게 이미 제공한 경우에는
              정정 처리결과를 제3자에게 지체 없이 통지하여 정정이 이루어지도록
              하겠습니다.
              <br /> ③ 회사는 이용자 또는 법정 대리인의 요청에 의해 해지 또는
              삭제된 개인정보는 개인정보처리방침에 명시된 바에 따라 처리하고 그
              외의 용도로 열람 또는 이용할 수 없도록 처리하고 있습니다.
              <br /> ④ 이용자는 개인정보의 삭제를 원하는 경우, 회원탈퇴를 통해
              개인정보를 삭제할 수 있습니다.{" "}
            </BasicText>
            <StyleSubTitle>
              6. 개인정보 자동 수집 장치에 관한 사항 (설치 운영 및 거부)
            </StyleSubTitle>{" "}
            <BasicText bold={400} color={Colors.gray4}>
              웹기반 서비스 제공을 위하여 쿠키를 설치·운영 할 수 있습니다.
              <br />
              <br />
              [쿠키란]
              <br />
              <br />
              이용자가 웹사이트를 접속할 때 해당 웹사이트에서 이용자의
              브라우저에 보내는 텍스트 파일로 이용자 PC에 저장됩니다.
              <br />
              <br />
              [사용목적]
              <br />
              <br />
              개인화 및 맞춤화된 서비스를 제공하기 위해 이용자의 정보를 저장하고
              수시로 불러오는 쿠키를 사용합니다. 웹사이트를 방문할 경우 이용자의
              디바이스에 저장되어 있는 쿠키를 읽어 이용자의 환경설정을 유지하고
              맞춤화된 서비스를 제공하게 됩니다. 쿠키는 개인을 식별하는 정보를
              자동적·능동적으로 수집하지 않으며, 이용자는 언제든지 이러한 쿠키의
              저장을 거부하거나 삭제할 수 있습니다. 단, 쿠키 저장을 거부하는
              경우에는 일부 서비스 이용이 어려울 수 있습니다.
              <br />
              <br />
              [쿠키 수집 거부]
              <br />
              <br />
              이용자는 쿠키 설치에 대한 선택권을 가지고 있으며, 웹브라우저 옵션
              ‘설정 - 개인정보보호 - 쿠키 및 기타 사이트 데이터’ 경로에서 쿠키
              설정을 통해 쿠키 허용 및 거부를 할 수 있습니다. 다만, 쿠키 설치를
              거부할 경우 웹 사용이 불편해지며, 로그인이 필요한 일부 서비스
              이용이 어려울 수 있습니다{" "}
            </BasicText>
            <StyleSubTitle>
              7. 행태정보 수집 이용 및 거부 등에 관한 사항
            </StyleSubTitle>{" "}
            <BasicText bold={400} color={Colors.gray4}>
              (주)엠에이디씨는 아래와 같이 행태정보를 수집 이용 처리합니다.
              <br />
              <br />
              {/* Table 필요 */}
              구분 내용 수집하는 행태정보의 항목 이용자의 서비스 내 방문 기록,
              활동 로그 및 검색 이력 행태정보 수집 방법 이용자의 정보를 저장하고
              수시로 찾아내는 쿠키(cookie) 설치 운용 이용자가 웹/앱 사이트 방문
              시 생성 정보 수집 툴 등을 통해 자동 수집 전송 이용자가 앱 실행 시
              생성 정보 수집 툴 등을 통해 자동 수집 전송 행태정보 수집 목적
              이용자의 행태정보를 분석하여 신규 서비스 개발, 서비스 개편 등의
              척도로 활용 인구 통계학적 특성에 기반한 추정 성·연령대와 관심사에
              따른 맞춤형 광고 및 콘텐츠 제공 행태정보 보유 이용기간 최대 1년간
              보관 후 복구 할 수 없는 방법으로 완전히 파기 또는 분리 보관 이용자
              통제권 행사방법 [웹 브라우저]
              <br /> - 맞춤형 광고 허용 여부 선택 <br />
              [앱]
              <br /> Android: 홈-설정-구글-광고-광고ID 삭제
              <br />
              iOS: 홈-설 정-개인정보보 호-추 적-앱이 추적을 요청하도록 허용(OFF)
              <br />
              관련 문의 및 피해구제 방법 서비스 담당 부서: CS 문의처 :
              madc_@naver.com{" "}
            </BasicText>
            <StyleSubTitle>
              8. 개인정보의 안전성 확보 조치에 관한 사항
            </StyleSubTitle>{" "}
            <BasicText bold={400} color={Colors.gray4}>
              회사는 이용자들의 개인정보 보호를 위해 다음과 같은 기술적∙관리적
              대책을 강구하고 있습니다.
              <br />
              <br />
              개인정보 암호화 이용자의 비밀번호 등 중요 정보는 암호화되어 저장
              및 관리되고 있으며, 개인정보의 확인 및 변경은 본인에 의해서만
              가능합니다.
              <br />
              <br />
              해킹 등에 대비한 대책
              <br /> - 회사는 해킹이나 악성코드에 의하여 이용자들의 개인정보가
              유출· 훼손되는 것을 방지하기 위하여 침입차단 시스템을 24시간
              운영하여 외부로부터의 무단접근을 통제하고 있으며, 백신 프로그램을
              설치하여 시스템이 악성코드나 바이러스에 감염되지 않도록 노력하고
              있습니다.
              <br /> - 또한 개인정보의 훼손에 대비하여 자료를 수시로 백업하고
              있고, 암호화 통신 등을 통하여 네트워크 상에서 개인정보를 안전하게
              전송할 수 있도록 하고 있습니다.
              <br /> - 더불어 회사는 더욱 안전한 개인정보 처리를 위해 최대한의
              기술적 방법을 구비하기 위해 노력하고 있습니다.
              <br />
              <br /> 개인정보 처리 직원의 최소화 및 교육 개인정보를 처리하는
              직원을 최소한으로 관리하며, 개인정보처리시스템에서 개인정보의
              다운로드가 가능한 직원들의 업무용 PC에 대해 외부 인터넷망과
              내부망을 분리하여 개인정보 유출 가능성을 줄이고 있습니다. 또한
              개인정보취급자에 대한 정기 교육, 전사 임직원에 대한 수시 교육을
              통해 개인정보의 중요성을 인식하고 있습니다.
              <br />
              <br /> 개인정보 보호전담 인력의 운영 개인정보 보호를 위해 개인정보
              보호전담 인력을 운영하고 있으며, 개인정보 처리방침의 이행사항 및
              개인정보 처리자의 준수 여부를 확인하여 문제가 발견될 경우 즉시
              수정하고 바로 잡을 수 있도록 노력하고 있습니다.
              <br />
              <br /> 위와 같은 노력 이외에 이용자 스스로도 제3자에게 비밀번호
              등이 노출되지 않도록 주의하셔야 합니다. 특히 비밀번호 등이
              공공장소에 설치한 PC를 통해 유출되지 않도록 항상 유의하시기
              바랍니다. 이용자의 ID와 비밀번호는 반드시 본인만 사용하시고
              비밀번호를 자주 변경, 관리하는 것이 좋습니다.{" "}
            </BasicText>
            <StyleSubTitle>9. 개인 위치정보의 처리</StyleSubTitle>
            <BasicText bold={400} color={Colors.gray4}>
              회사는 위치정보의 보호 및 이용 등에 관한 법률을 준수해 이용자의
              개인위치정보를 보호합니다.
              <br />
              <br /> 개인위치정보 처리에 관한 상세한 내용은 (주)엠에이디씨
              위치기반 서비스 이용약관을 통해 확인 가능합니다.
              <br />
              <br /> 회사는 개인위치정보주체에게 아래와 같은 서비스를 제공할
              목적으로 개인위치정보를 이용하고 있으며, (주)엠에이디씨
              위치기반서비스 이용약관에 따른 위치기반서비스 이용 및 제공 목적을
              달성한 때에는 지체없이 개인 위치정보를 파기합니다.
              <br /> - 위치정보를 활용한 정보 검색결과 및 콘텐츠 제공 또는 추천
              서비스
              <br /> - 위치기반의 콘텐츠 분류를 위한 콘텐츠 태깅
              <br /> - 위치기반의 맞춤형 광고
              <br />
              <br />
              회사는 위치정보의 보호 및 이용 등에 관한 법률 제16조 제2항에
              근거하여 개인위치정보주체에 대한 위치정보 이용ㆍ제공사실
              확인자료를 위치정보시스템에 자동으로 기록하며, 6개월간 보관합니다.
              <br />
              <br /> 회사는 개인위치정보의 처리목적을 달성해 파기가 필요해질
              경우 복구 또는 재생 불가능한 아래와 같은 방법으로 파기합니다.
              <br /> - 종이에 출력된 개인 정보: 분쇄기로 분쇄하거나 소각
              <br /> - 전자적 파일 형태로 저장된 개인 정보 : 기록을 재사용할 수
              없는 기술적 방법을 사용하여 삭제
              <br />
              <br /> 회사는 개인위치정보를 이용자가 지정하는 제3자에게 제공하는
              경우 개인위치정보를 수집한 통신단말장치로 매회 이용자에게 제공받는
              자, 제공일시 및 제공목적을 즉시 통지합니다. 단, 아래의 경우
              이용자가 미리 특정하여 지정한 통신단말장치 또는 전자우편주소,
              온라인게시 등으로 통지합니다.
              <br /> - 개인위치정보를 수집한 당해 통신단말장치가 문자, 음성 또는
              영상의 수신기능을 갖추지 아니한 경우
              <br /> - 이용자의 개인위치정보를 수집한 통신단말장치 외의
              통신단말장치 또는 전자우편주소, 온라인게시 등으로 통보할 것을 미리
              요청한 경우
              <br />
              <br /> 회사의 8세 이하 아동 등의 보호의무자의 권리·의무 및 그
              행사방법은 아래와 같습니다.
              <br /> - 회사는 아래의 경우에 해당하는 자(이하 “8세 이하의 아동
              등”)의 위치정보의 보호 및 이용 등에 관한 법률 제26조2항에 해당하는
              자(이하 “보호의무자”)가 8세 이하의 아동 등의 생명 또는 신체보호를
              위하여 개인위치정보의 이용 또는 제공에 동의하는 경우에는 본인의
              동의가 있는 것으로 봅니다.
              <br /> 가. 8세 이하의 아동
              <br /> 나. 피성년후견인
              <br /> 다. 장애인복지법 제2조제2항제2호에 따른 정신적 장애를 가진
              사람으로서 장애인고용촉진 및 직업재활법 제2조제2호에 따른
              중증장애인에 해당하는 사람(장애인복지법 제32조에 따라 장애인
              등록을 한 사람만 해당한다)
              <br /> - 8세 이하의 아동 등의 생명 또는 신체의 보호를 위하여
              개인위치정보의 이용 또는 제공에 동의를 하고자 하는 보호의무자는
              서면동의서에 보호의무자임을 증명하는 서면을 첨부하여 회사에
              제출하여야 합니다. <br />
              보호의무자는 8세 이하의 아동 등의 개인위치정보 이용 또는 제공에
              동의하는 경우 개인위치정보주체 권리의 전부를 행사할 수 있습니다.
              <br />
              <br />
              회사는 위치정보를 보호하고 위치정보 관련 불만을 처리하기 위해
              위치정보 관리책임자를 지정하고 있습니다.
              <br /> - 위치정보 관리책임자: 김홍민
              <br /> - 직함: 대표이사
              <br /> - 연락처: madc_@naver.com
            </BasicText>
            <StyleSubTitle>10. 개인정보 보호책임자 및 담당부서</StyleSubTitle>
            <BasicText bold={400} color={Colors.gray4}>
              이용자는 회사의 서비스를 이용하는 중 발생하는 모든 개인정보 관련
              문의, 불만처리 등에 관한 사항을 개인정보 관리 책임자 혹은
              담당부서로 문의할 수 있습니다. 회사는 이용자의 문의에 대한
              신속하고 성실한 답변 및 처리를 위해 노력하고 있습니다.
              <br /> [개인정보 관리담당자]
              <br /> - 담당부서: (주)엠에이디씨
              <br /> - 개인정보 관리책임자: 김홍민
              <br /> - 개인정보 관리담당자: 김홍민
              <br /> - 이메일주소: madc_@naver.com
              <br />
              <br />
              기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래
              기관으로 문의하시기 바랍니다.
              <br /> - 개인정보침해신고센터 (privacy.kisa.or.kr / 국번없이 118)
              <br /> - 대검찰청 사이버수사과 (www.spo.go.kr / 국번없이 1301)
              <br /> - 경찰청 사이버수사국 (ecrm.police.go.kr / 국번없이 182)
            </BasicText>
            <StyleSubTitle>11. 개정 전 고지의무 등 안내</StyleSubTitle>
            <BasicText bold={400} color={Colors.gray4}>
              ① 회사는 개인정보 처리방침에 대한 변경이 있을 경우에는 개정
              개인정보 처리방침의 시행일로부터 최소 7일 전에 홈페이지의 공지사항
              또는 이메일을 통해 고지합니다. 다만, 이용자의 권리 또는 의무에
              중요한 내용의 변경이 있을 경우에는 최소 30일전에 고지합니다.
              <br />
              <br /> ② 회사가 제1항에 따라 변경 내용을 고지하면서, 변경
              적용일까지 거부의사를 표시하지 않으면 의사표시가 된 것으로 본다는
              뜻을 고지하였음에도 불구하고 이용자가 명시적으로 거부의사를
              표시하지 아니하는 경우 이용자가 변경 내용에 동의한 것으로 봅니다.
              <br />
              <br />③ 회사는 제 2항에도 불구하고 이용자로부터 개인정보를 추가
              수집하거나 제3자에게 제공하는 경우에는 이용자 본인으로부터 이에
              대하여 별도 동의 절차를 거칩니다.
            </BasicText>
            <StyleSubTitle>12. 개정이력 본 개인정보</StyleSubTitle>
            <BasicText bold={400} color={Colors.gray4}>
              처리방침은 2024년 02월 05일부터 적용됩니다.
              <br />
              <br />- 개인정보 처리방침 변경공고일 : 2023년 01월 29일
              <br /> - 개인정보 처리방침 시행일 : 2024년 02월 05일
            </BasicText>
          </div>
          {/* <Button
            onClick={() => nvaigate(-1)}
            style={{
              marginTop: 20,
              width: 200,
            }}
          >
            확인
          </Button> */}
        </div>
      </StyledMainContainer>
    </>
  );
};

export default PrivacyPolicy;

const StyledMainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // height: 100vh;

  .text-area {
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }
`;
